import React, { useEffect } from 'react';

export default function OneSignal(props) {

	useEffect(() => {
		let jquery = document.createElement('script');
		jquery.type = 'text/javascript';
		jquery.async = false;
		jquery.src = 'https://cdn.onesignal.com/sdks/OneSignalSDK.js';
		jquery.onload = function() {
			let setupScript = document.createElement('script');
			setupScript.type = 'text/javascript';
			setupScript.async = false;
			setupScript.innerHTML = `
                var OneSignal = window.OneSignal || [];
                OneSignal.push(function () {
                    OneSignal.showHttpPrompt();
                    OneSignal.init({
                        appId: "b8a15812-7700-4388-9a82-916fd2019d80",
                        autoRegister: false,
                        promptOptions: {
                            /* actionMessage limited to 90 characters */
                            actionMessage: "Encuentra tu hogar en TOCTOC, Suscríbete y conoce primero nuestras Noticias.",
                            /* acceptButtonText limited to 15 characters */
                            acceptButtonText: "ACEPTAR",
                            /* cancelButtonText limited to 15 characters */
                            cancelButtonText: "No, gracias",
                            exampleNotificationCaption: "exampleNotificationMessageMobile"
                        }
                    });
                });
            `;
			document.body.appendChild(setupScript);
		}

		document.body.appendChild(jquery);
	}, [])

	return (
		<>
		</>
	)
}