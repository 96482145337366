import { useCallback, useContext, useEffect, useState } from 'react';
import Head from 'next/head';

import { FullHeader } from '@toctoc/design-system';

import { asyncServiceLoader } from 'modules';
import { BannerHeader } from 'components/bannerHeader/view';
import Footer from 'components/footer/view';
import { GoogleTagManager } from 'components/googleTagManager/GoogleTagManager';
import OneSignal from 'components/oneSignal/oneSignal.jsx';
import { iLayoutProps } from './iProps';
import { useRouter } from 'next/router';
import { KinesisEventos } from 'components/googleTagManager/KinesisEventos.js';
import { UserContext } from './UserContext';
import { filterMenuOptionsUtil } from 'utils/aclToctoc/filterMenuOptionsUtil';
import { VWOScript } from 'components/VWO/VWOScript';

const urlBaseCloudfrontImagenes = process.env.NEXT_PUBLIC_URL_TOCTOCIMAGENES;
const urlSSO = process.env.NEXT_PUBLIC_URL_SSO;

type SSOLink = { name: string; href: string };

function useSSOLinks(urlSSO = "") {
	const [ssoLinks, setSSOLinks] = useState<SSOLink[]>([{ name: 'Iniciar sesión', href: `${urlSSO}` }]);
	useEffect(() => {
		if (typeof window === 'undefined') return;

		const currentPage = new URL(window.location.href);
		const urlWithoutParams = `${currentPage.origin}${currentPage.pathname}`;
		const base64Url = Buffer.from(urlWithoutParams).toString('base64');

		setSSOLinks([
			{ name: 'Iniciar sesión', href: `${urlSSO}?url=${base64Url}` }
		]);
	}, [urlSSO]);

	return ssoLinks;
}

export default function TocTocLayout(props: iLayoutProps) {

	const  { sessionState, setSessionState, sessionSetup } = useContext(UserContext);
	const sendClientEvent = asyncServiceLoader("sendClientEvent");
	const {
		sectionName,
		datosBannerHeader,
		urlBase,
		children,
		canonicalSection,
		headerStaticProps,
		activeMenu,
		canonicalBasePath,
		containerClassName,
		idGTM,
		title,
		wootricDefaultSection,
		footerData,
	} = props;


	const router = useRouter()

	// Nota(benjavicente): esto debería ser parte de una API de React para el SSO
	const ssoLinks = useSSOLinks(urlSSO);
	const logout = useCallback(() => setSessionState({ logeado: false, user: null }), [setSessionState]);
	useEffect(sessionSetup, [])  /* eslint-disable-line react-hooks/exhaustive-deps */
	useEffect(() => {
		const url = new URL(window.location.href);
		if (url.searchParams.has('token')) {
			url.searchParams.delete('token');
			router.replace(url.toString(), undefined, { shallow: true });
		}
	}, []);

	//Filtrado de menú
	let tempHeaderStaticProps = headerStaticProps
	if (typeof window !== 'undefined' && sessionState?.user?.role) {
		tempHeaderStaticProps = filterMenuOptionsUtil(headerStaticProps, sessionState?.user?.role)
	}

	return (
		<div className={containerClassName}>
			<Head>
				<title>{title}</title>
				<meta
					name="description"
					content="Departamentos en venta, casas en venta, arriendo de casas y arriendo de departamentos en Santiago y todo Chile."
				/>
				<meta
					property="og:title"
					content="TOCTOC - Arriendo de casas y departamentos y venta de departamentos y casas"
				/>
				<meta
					property="og:description"
					content="Más de 140 mil departamentos y casas en venta o en arriendo, en Santiago y en todo Chile."
				/>
				<link rel="canonical" href={`${urlBase}${canonicalBasePath}${canonicalSection ? canonicalSection : ''}`} />
			</Head>

			<main className={'styles.main'}>
				<GoogleTagManager idGTM={idGTM} />
				<VWOScript accountId={process.env.NEXT_PUBLIC_ID_ACCOUNT_VWO!}/>
				<KinesisEventos eventCallback={sendClientEvent} />
				<OneSignal />
				<BannerHeader datosBannerHeader={datosBannerHeader} />
				<FullHeader
					{...tempHeaderStaticProps}
					homeHref="/"
					activeMenu={activeMenu}
					onLogout={logout}
					user={sessionState?.user}
					ssoLinks={ssoLinks}
				/>
				{children}
			</main>
			<Footer urlBase={urlBase} urlBaseCloudfrontImagenes={urlBaseCloudfrontImagenes} footerData={footerData} />
		</div>
	);
}
