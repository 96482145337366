import ACLToctoc from './acl'
import { loadSimpleWithSessionHeaderStaticProps } from '@toctoc/design-system'
import { getJsonSchemaRole } from './jsonSchemaRole'
import { ePerfilRole } from './enums';

export const filterMenuOptionsUtil = (headerStaticProps: any, role: string) => {
	const acl = new ACLToctoc(getJsonSchemaRole())
	let decodeRol = "";
	try {
		decodeRol = Buffer.from(role, "base64").toString();
		if (decodeRol.split(".").length < 3) // This could mean an invalid role
			console.warn("Watch-out, this seems an invalid role")
	} catch (ex) {
		decodeRol = ""
		console.warn("Unable to read current role")
	}
	return {
		...headerStaticProps,
		userMenu: acl.filterMenuOptions<Awaited<ReturnType<typeof loadSimpleWithSessionHeaderStaticProps>>["userMenu"][0]>({
			role: decodeRol ? decodeRol : "", userMenu: headerStaticProps.userMenu
		})
	}
}

const isAnyBrokerRole = (roleB64: string = ""): boolean => {
	const code = roleB64 ? getPerfilByRol(atob(roleB64)) : "";
	return [
		ePerfilRole.clienteCorredor + "",
		ePerfilRole.clienteCorredora + "",
		ePerfilRole.clienteCorredoraIntegrada + "",
		ePerfilRole.clienteCorredorMatriz + "",
		ePerfilRole.PublicadorIntegrado + ""
	].includes(code);
};

const getPerfilByRol = (role: string) => {
	const acl = new ACLToctoc()
	try{
		return acl.getProfileCode(role);
	}catch(error){
		return "-1";
	}
}